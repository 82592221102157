.loading-screen {
  position: fixed;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  background-color: #fff;
  display: flex;
  justify-content: center;
  align-items: center;
  z-index: 9999;
}

.loading-spinner {
  -webkit-animation: spin1 1.5s infinite linear;
  -moz-animation: spin1 1.5s infinite linear;
  -o-animation: spin1 1.5s infinite linear;
  -ms-animation: spin1 1.5s infinite linear;
  animation: spin1 1.5s infinite linear;
  display: block;
}

@keyframes spin1 {
  0% {
    transform: rotate(0deg);
  }
  100% {
    transform: rotate(360deg);
  }
}
