:root {
  --primary: #f5f1f0;
  --primary-text: #141414;
  --secondary: #000000;
  --secondary-text: #fcb21b;
  --tertiary: #fff;
  --tertiary-text: #0000007f;
  --accent: #3b16f3;
  --accent-text: #00000052;
  --active: #2024ff;
  --negative: #cc352a;
}

[data-theme="dark"] {
  --primary: #161616;
  --primary-text: #fff;
  --secondary: #1c1b23;
  --secondary-text: #fcb21b;
  --tertiary: #333333;
  --tertiary-text: #dfdfdfba;
  --accent: #5433ff;
  --accent-text: #00000052;
  --active: #20bdff;
  --negative: #d66f68;
}

* {
  transition: background-color 0.3s ease-in-out, color 0.1s ease-in-out;
  font-family: "Raleway", sans-serif;
  scroll-behavior: smooth;
}

.app {
  display: flex;
  flex-direction: column;
  position: relative;
  max-width: 100%;
  background-color: var(--primary);
}

.logo {
  height: 3rem;
}

.header {
  display: flex;
  justify-content: space-between;
  align-items: center;
  position: sticky;
  top: 0;
  max-width: 100%;
  padding: 0 6rem;
  background-color: var(--tertiary);
  box-shadow: 2px 1px 24px 1px var(--accent-text);
  z-index: 999;
}

.contact_button {
  text-decoration: none;
}

.mobile_nav {
  display: none;
}

.hamburger {
  display: none;
}

.header_right,
.header_center {
  display: flex;
  gap: 3rem;
  font-weight: 400;
}

.header_center p {
  cursor: pointer;
}

.header_right p {
  border: 2px solid var(--secondary-text);
  padding: 15px 25px;
  border-radius: 8px;
  font-weight: bold !important;
  color: var(--secondary-text) !important;
  font-size: 16px !important;
  cursor: pointer;
}

.header p {
  font-size: 18px;
  font-weight: 600;
  color: var(--tertiary-text);
}

.cards {
  display: grid;
  grid-template-columns: repeat(3, 1fr);
  gap: 20px;
}

.card {
  background-color: var(--tertiary);
  color: var(--primary-text);
  padding: 15px;
  margin-bottom: 25px;
  border-bottom: 4px solid var(--secondary-text);
  border-radius: 12px;
  box-shadow: 2px 1px 24px 1px #0000001f;
}

.card p {
  font-weight: 500 !important;
}

.card span {
  color: var(--secondary-text);
  font-size: 40px;
}

.button {
  background: var(--secondary-text);
  color: var(--primary);
  font-weight: bold;
  min-width: 50px;
  min-height: 40px;
  padding: 15px 25px;
  border-radius: 8px;
  font-size: 1rem;
  outline: none;
  border: 1px solid var(--secondary-text);
}

button {
  cursor: pointer;
}

.button:hover {
  border: 1px solid #000;
  background: var(--tertiary);
  color: var(--secondary);
  cursor: pointer;
}

.material-symbols-outlined {
  font-variation-settings: "FILL" 0, "wght" 400, "GRAD" 0, "opsz" 24;
}

#theme__switcher {
  background: var(--primary-text);
  color: var(--primary);
  width: 50px;
  border-radius: 20px;
  height: 40px;
  padding: 7px;
  margin-top: 20px;
  outline: none;
  border: none;
}

@media screen and (max-width: 480px) {
  body {
    overflow-x: hidden;
  }
  .cards {
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
  }

  .card {
    padding: 15px;
    margin-bottom: 25px;
    border-bottom: 4px solid var(--secondary-text);
    border-radius: 12px;
    box-shadow: 2px 1px 24px 1px #0000001f;
  }

  .header_left img {
    height: 35px;
    width: 100px;
  }
  .header_center,
  .header_right {
    display: none;
  }
  .hamburger {
    display: block !important;
  }

  .header {
    display: flex;
    position: sticky;
    top: 0;
    padding: 1rem;
  }

  .NavLinks {
    position: absolute;
    right: 0;
    top: 0;
    background-color: var(--secondary-text);
    height: 100vh;
    padding: 0 1rem;
    opacity: 0.9;
  }

  .mobile_nav {
    display: flex;
    align-items: center;
    gap: 20px;
  }

  .mobile_nav p {
    font-size: 14px;
  }

  .button {
    min-width: 50px;
    min-height: 40px;
    padding: 10px 15px;
    border-radius: 8px;
    font-size: 12px;
    outline: none;
    border: 1px solid var(--secondary-text);
  }
}
