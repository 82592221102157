#landing_section {
  background-image: url("../wave-4.svg");
  background-position: bottom;
  background-repeat: no-repeat;
  padding: 0 9rem;
  min-height: 100vh;
  display: flex;
  text-align: left;
  color: var(--primary-text);
}

#landing_section h1 {
  font-size: 3.3em;
  margin-top: 4rem;
  line-height: 63px;
  vertical-align: baseline;
  color: var(--primary-text);
}

.landing_left {
  animation: fade-left 2.5s cubic-bezier(0.74, 0.06, 0.4, 0.92);
  animation-iteration-count: 1;
}

.landing_image {
  margin-left: 16rem;
  margin-top: 1rem;
}

.landing_image img {
  height: 440px;
  width: 440px;
  animation: zoom-out 2.5s cubic-bezier(0.74, 0.06, 0.4, 0.92);
  animation-iteration-count: 1;
}

#service_section {
  margin: 0 9rem;
  text-align: left;
}

#service_section h2,
#clients_section h2,
#contact_us h2 {
  font-size: 40px;
  margin-top: 5rem;
  text-align: center;
  color: var(--primary-text);
}

#clients_section {
  margin: 0 9rem;
}

/* .client_grid {
  display: grid;
  gap: 30px;
  grid-template-columns: repeat(5, 1fr);
} */

.slider-container {
  width: 60rem;
  margin: 0 auto;
}

.clients_mobile {
  display: none;
}

.slick-arrow::before {
  color: var(--primary-text);
}

.slick-track {
  display: flex;
  gap: 130px;
}

#service_section h2 {
  text-align: left !important;
}

#service_section p {
  font-weight: 600;
  color: var(--tertiary-text);
  font-size: 18px;
}

#landing_section h1 span {
  color: #fcb21b;
}

#landing_section p {
  font-weight: 600;
}

#landing_section p span {
  color: #20c845;
  vertical-align: middle;
}

.contactus_flex {
  display: flex;
  margin: 0 9rem 25rem;
  justify-content: space-between;
  padding: 1rem 6rem;
  border-radius: 20px;
  background-color: var(--tertiary);
  height: 400px;
  border: 2px solid var(--secondary-text);
}

.contact_mobile {
  display: none;
}

.client_logo {
  border-radius: 50%;
  height: 200px;
  width: 210px;
}

.contactus_flex h3 {
  font-size: 35px;
  color: var(--secondary-text);
}

.contactus_flex p {
  color: var(--primary-text);
}

.footer {
  position: absolute;
  bottom: 0;
  right: 0;
  left: 0;
  display: flex;
  justify-content: flex-start;
  padding: 3rem 12rem 0;
  gap: 8rem;
  align-items: flex-start;
  height: 250px;
  background-color: var(--tertiary);
  color: var(--primary-text);
  border-top: 2px solid var(--secondary-text);
  box-shadow: 2px 1px 24px 1px #0000001f;
}

.footer a,
.footer_mobile a {
  text-decoration: none;
  color: var(--primary-text);
}

.footer .quick {
  cursor: pointer;
  text-decoration: none;
}

.footer_mobile {
  display: none;
}

.footer div {
  width: 500px;
}

form {
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  gap: 2em;
}

input,
textarea {
  width: 350px;
  max-width: 350px;
  word-wrap: normal;
  padding: 12px;
  border: none;
  border-radius: 4px;
  box-shadow: 2px 2px 7px 0 rgb(0, 0, 0, 0.2);
  outline: none;
  color: dimgray;
}

input[type="submit"] {
  background-color: var(--primary-text);
  color: var(--primary);
  width: 200px;
  margin: 0 auto;
  cursor: pointer;
}

input:invalid {
  animation: justshake 0.3s forwards;
  color: red;
}

@keyframes justshake {
  25% {
    transform: translateX(5px);
  }

  50% {
    transform: translateX(-5px);
  }

  75% {
    transform: translateX(5px);
  }

  100% {
    transform: translateX-(5px);
  }
}
@keyframes zoom-out {
  0% {
    opacity: 0;
    transform: translateY(60px);
  }
  100% {
    opacity: 1;
  }
}

@keyframes fade-left {
  0% {
    opacity: 0;
    transform: translateX(-60px);
  }
  100% {
    opacity: 1;
  }
}

@media screen and (max-width: 480px) {
  #landing_section {
    display: flex;
    flex-direction: column;
    align-items: flex-start;
    padding: 1rem 1.5rem;
    min-height: 100%;
    background-image: none;
  }

  .landing_image {
    margin: 3rem 0 0;
  }

  .landing_image img {
    height: 320px;
    width: 320px;
    display: flex !important;
    justify-content: center !important;
    align-items: center !important;
  }

  .slider-container {
    display: none;
  }

  .clients_mobile {
    display: grid;
    grid-template-columns: repeat(2, 1fr);
    justify-content: center;
    align-items: center;
  }

  #clients_section {
    margin: 0;
  }

  .slick-track {
    display: flex;
    gap: 30px;
  }

  #landing_section p {
    font-size: 16px;
    margin: 2px 0;
  }
  .client_logo {
    margin: 1rem;
    height: 120px;
    width: 120px;
  }
  #landing_section h1 {
    font-size: 2em;
    margin-top: 2rem;
    line-height: 33px;
  }
  #service_section {
    margin: 0 1.5rem;
  }
  #service_section h2,
  #clients_section h2,
  #contact_us h2 {
    font-size: 30px;
    text-align: left;
  }

  #clients_section,
  #contact_us {
    margin: 0 1.5rem;
  }

  #service_section p {
    font-weight: 500;
    color: #0000007f;
    font-size: 16px;
  }

  .contactus_flex {
    display: none;
  }

  .contact_mobile {
    display: flex;
    justify-content: center;
    align-items: center;
  }

  .footer_mobile {
    display: flex;
    margin: 2rem auto 0;
    flex-direction: column;
    padding: 2rem;
    background-color: var(--tertiary);
    color: var(--primary-text);
    border-top: 2px solid var(--secondary-text);
    box-shadow: 2px 1px 24px 1px #0000001f;
  }

  .footer {
    display: none;
  }

  .contactus_flex h3 {
    font-size: 25px;
  }

  input,
  textarea {
    width: 250px;
  }
}
@media screen and (max-width: 1024px) {
}
